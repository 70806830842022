import { IMenuGroup } from '@/types/menu';

export const menus: IMenuGroup[] = [
  {
    header: 'Main',
    children: [
      {
        icon: 'mdi-view-dashboard',
        title: 'Dashboard',
        link: '/main/dashboard',
      },
      {
        icon: 'mdi-account',
        title: 'Profile',
        link: '/main/profile/view',
      },
      {
        icon: 'mdi-account-edit',
        title: 'Edit Profile',
        link: '/main/profile/edit',
      },
      {
        icon: 'mdi-account-key',
        title: 'Change Password',
        link: '/main/profile/password',
      },
    ],
  },
  {
    header: 'Analyses',
    children: [
      {
        icon: 'mdi-bullseye-arrow',
        title: 'Submission',
        link: '/main/analyses/view',
      },
    ],
  },
  {
    header: 'Admin',
    children: [
      {
        icon: 'mdi-account-group',
        title: 'Manage Users',
        link: '/main/admin/users/all',
      },
      {
        icon: 'mdi-account-plus',
        title: 'Create User',
        link: '/main/admin/users/create',
      },
    ],
  },
];

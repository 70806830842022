















































































































import { Component, Vue } from 'vue-property-decorator';
import { appName } from '@/env';
import { menus } from '@/menu';
import {
  readDashboardMiniDrawer,
  readDashboardShowDrawer,
  readHasAdminAccess,
  readToken,
} from '@/store/main/getters';
import {
  commitSetDashboardShowDrawer,
  commitSetDashboardMiniDrawer,
} from '@/store/main/mutations';
import { dispatchLogOut } from '@/store/main/actions';
import { IMenuGroup } from '@/types/menu';
import { fileAPI } from '@/apis';

const routeGuardMain = async (to: any, from: any, next: any) => {
  if (to.path === '/main') {
    next('/main/dashboard');
  } else {
    next();
  }
};
@Component
export default class Main extends Vue {
  public appName = appName;
  public menus: IMenuGroup[] = menus;
  public fileId: string = '';

  public beforeRouteEnter(to: any, from: any, next: any) {
    routeGuardMain(to, from, next);
  }

  public beforeRouteUpdate(to: any, from: any, next: any) {
    routeGuardMain(to, from, next);
  }

  get miniDrawer() {
    return readDashboardMiniDrawer(this.$store);
  }

  get showDrawer() {
    return readDashboardShowDrawer(this.$store);
  }

  set showDrawer(value) {
    commitSetDashboardShowDrawer(this.$store, value);
  }

  public switchShowDrawer() {
    commitSetDashboardShowDrawer(
      this.$store,
      !readDashboardShowDrawer(this.$store),
    );
  }

  public switchMiniDrawer() {
    commitSetDashboardMiniDrawer(
      this.$store,
      !readDashboardMiniDrawer(this.$store),
    );
  }

  public get token() {
    return readToken(this.$store);
  }

  public get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  public async logout() {
    await dispatchLogOut(this.$store);
  }

  public async submit() {
    try {
      if (this.fileId === '') {
        return;
      }
      await fileAPI.getFile(this.token, this.fileId);
      await this.$router.push({
        name: 'analysis-result',
        params: { fid: this.fileId },
      });
    } catch (err) {
        console.error(err);
    }
  }
}
